// Function to flash message at bottom of screen
// export function flash(type, message) {
flash = (type, message) => {
    // Build the alert message html using template
    var template = $.trim($('#alert-message-template').html());
    var html = template
        .replace(/{%alert_type%}/ig, type)
        .replace(/{%alert_message%}/ig, message);

    // Show flash at bottom of screen
    // console.log(html);
    $("#flash-message-box").html("").html(html).fadeIn(500);
    setTimeout(function() {
        // Hide it after a few secs
        $("#flash-message-box").fadeOut(500);
    }, 5000);
};

format_date = (date) => {
    // Check for null
    if (date == null) {
        return null;
    } else {
        // Convert given string date to date format
        date = new Date(date);
        return ('0' + date.getDate()).slice(-2) + "/" + ('0' + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2);
        // return ('0' + date.getDate()).slice(-2) + "/" + ('0' + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2) + ' ' + (date.getHours() < 12 ? 'AM' : 'PM');
    }
}

// Function to return date in format dd mmm yyyy
format_date_short = (date) => {
    // Month array
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // Convert given string date to date format
    date = new Date(date);

    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
}

// Function to capitalize text
capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}