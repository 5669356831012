// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("datatables.net-dt")
require("src/utils")

// Import popper & bootstrap asynchronously
import ("src/plugins")
// import ("src/utils")
import ("css/site")
import ("css/datatables")

// Someone suggested to add this code for bootstrap js functions to work (e.g. modal)
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// Added to get typeahead/bloodhound working
import typeahead from "typeahead.js";
// window.typeahead = typeahead;
import Bloodhound from "typeahead.js";
window.Bloodhound = Bloodhound;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)